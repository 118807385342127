exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-coupcoins-jsx": () => import("./../../../src/pages/coupcoins.jsx" /* webpackChunkName: "component---src-pages-coupcoins-jsx" */),
  "component---src-pages-cover-br-jsx": () => import("./../../../src/pages/cover/br.jsx" /* webpackChunkName: "component---src-pages-cover-br-jsx" */),
  "component---src-pages-cover-en-jsx": () => import("./../../../src/pages/cover/en.jsx" /* webpackChunkName: "component---src-pages-cover-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-resume-br-jsx": () => import("./../../../src/pages/resume/br.jsx" /* webpackChunkName: "component---src-pages-resume-br-jsx" */),
  "component---src-pages-resume-en-jsx": () => import("./../../../src/pages/resume/en.jsx" /* webpackChunkName: "component---src-pages-resume-en-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-utils-e-0138-c-jsx": () => import("./../../../src/pages/utils/e0138c.jsx" /* webpackChunkName: "component---src-pages-utils-e-0138-c-jsx" */),
  "component---src-pages-utils-index-jsx": () => import("./../../../src/pages/utils/index.jsx" /* webpackChunkName: "component---src-pages-utils-index-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/blog-category.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../../../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-comic-list-jsx": () => import("./../../../src/templates/comic-list.jsx" /* webpackChunkName: "component---src-templates-comic-list-jsx" */),
  "component---src-templates-comic-post-jsx": () => import("./../../../src/templates/comic-post.jsx" /* webpackChunkName: "component---src-templates-comic-post-jsx" */),
  "component---src-templates-open-graph-image-jsx": () => import("./../../../src/templates/open-graph-image.jsx" /* webpackChunkName: "component---src-templates-open-graph-image-jsx" */)
}

